<template>
  <div>
    <!-- https://codepen.io/luizarusso/pen/zYxLOPb -->
    <data-iterator :headers="payloadHeaders" :payload="payload" class="ma-2"></data-iterator>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title class="primary darken-2 white--text pa-4 pb-3">
          <span class="body-1 font-weight-medium"> {{ modelObj._id ? 'Update Course' : 'Add Course'}} </span>
        </v-card-title>
        <v-card-text class="pa-2 pb-1">
          <v-form ref="form">
            <v-container fluid class="pa-3">
              <v-row no-gutters align="center">
                <v-col cols="12">
                  <v-text-field outlined dense hide-details label="Name" v-model="modelObj.name"
                  :rules="setValidation('requiredValidation')" validate-on-blur></v-text-field>
                </v-col>
                <v-col cols="6" class="py-2">
                  <v-text-field outlined dense hide-details label="Code" v-model="modelObj.code" validate-on-blur></v-text-field>
                </v-col>
                <v-col cols="6" class="py-2">
                  <v-checkbox outlined dense hide-details label="Is Active" v-model="modelObj.isactive" class="mx-5"></v-checkbox>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-simple-table>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="px-1 font-weight-black"> Term label </th>
                          <th class="px-1 font-weight-black"> Subjects </th>
                          <th class="px-1 text-center">
                            <v-btn color="success" text fab dark x-small class="mt-1 pb-0" @click="modelObj.terms.push({})">
                              <v-icon dark> mdi-plus-circle </v-icon>
                            </v-btn>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(termitem, termindex) in modelObj.terms" :key="`termitem_${termindex}`">
                          <td class="px-0 py-2">
                            <v-combobox v-model="termitem.name" hide-details :items="[]" outlined dense item-text="name" item-value="_id"
                              :key="`termitem_term_${termindex}`" style="width: 240px;" :rules="setValidation('requiredValidation')"
                            ></v-combobox>
                          </td>
                          <td class="px-0 py-2">
                            <v-autocomplete multiple v-model="termitem.subjects" hide-details :items="listOfSubjects" outlined dense item-text="name" item-value="_id"
                              :key="`termitem_subject_${termindex}`" style="width: 240px;" chips small-chips
                            ></v-autocomplete>
                          </td>
                          <td class="px-0 py-2 text-center">
                            <v-btn v-if="termindex" color="black" text fab dark x-small class="mt-1 pb-0" @click="modelObj.terms.splice(termindex, 1)">
                              <v-icon dark> mdi-minus-circle </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
            <!-- <treeview class="item" :config="config" :depth="1" :item="treeData" @make-folder="makeFolder" @add-item="addItem" @remove-item="removeTtem" /> -->
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile color="success" class="mx-2" @click="saveUpdateHandler"> {{ modelObj._id ? 'Update' : 'Save' }} </v-btn>
          <v-btn tile @click="dialog = false; $refs.form.reset();" color="error"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      open: [1, 2],
      search: null,
      config: {
        maxDepth: 1
      },
      treeData: {
        name: "Course Name",
        id: 1,
        children: [
          {
            id: 1,
            name: "1st year 1st term"
            // children: [{ name: "hello" }]
          },
          { id: 2,name: "1st year 2nd term" },
          { id: 3, name: "2nd year 1st term" }
        ]
      },
    //
      dialog: false,
      listOfSubjects: [],
      modelObj: {
        terms: []
      },
      payload: {
        moduleIcon: 'mdi-book',
        create: () => {
          this.modelObj = { isactive: true, terms: [{ name: '1st Term' }] }
          this.dialog = true
        },
        showBackBtn: () => this.$router.go(-1),
        items: [],
        showSelect: false,
        actionsList: [{
          click: (item) => this.editHandler(item._id),
          icon: 'mdi-pencil',
          color: 'primary'
        }, {
          click: (item) => this.deleteHandler(item._id),
          icon: 'mdi-delete',
          color: 'error'
        }]
      }
    }
  },
  components: {
    // 'treeview': () => import('@/components/TreeView.vue'),
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  beforeMount () {
    this.$store.dispatch('getSubjects').then((subjects) => {
      this.listOfSubjects = subjects
    })
    this.getListHandler()
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Code',
        // align: 'start',
        sortable: false,
        value: 'code'
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Is Active',
        value: 'isactive',
        type: 'BOOLEAN'
      }, {
        text: 'Actions',
        value: 'actions',
        width: '20%'
      }]
    }
  },
  methods: {
    makeFolder (item) {
      this.$set(item, 'children', []);
      this.addItem(item);
    },
    addItem (item) {
      item.children.push({ name: 'new stuff' });
    },
    removeTtem (parent, item) {
      console.log(parent, item)
      // item.splice(index, 1);
    },
    getListHandler () {
      this.$_execute('get', 'courses').then(({ data }) => {
        this.payload.items = data
      })
    },
    saveUpdateHandler () {
      if (this.$refs.form && this.$refs.form.validate()) {
        let model = this.$_copy(this.modelObj)
        let url = model._id ? `courses/${model._id}` : `/courses`
        let method = model._id ? `put` : `post`
        model.terms.forEach((termitem) => {
          if (!termitem.subjects || !termitem.subjects.length) termitem.subjects = []
          termitem.subjects = termitem.subjects.map((item) => ({ subjectId: item }))
        })
        this.$_execute(method, url, model)
          .then(() => {
            this.$root.$emit('snackbar', { message: model._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
            this.$refs.form.reset()
            this.modelObj = {}
            this.getListHandler()
          })
          .finally(() => {
            this.dialog = false
          })
      } else {
        this.$root.$emit('snackbar', {
          color: 'error',
          message: 'Please check the required fields!'
        })
      }
    },
    editHandler (id) {
      this.$_execute('get', `courses/${id}`)
        .then(({ data }) => {
          data.terms.forEach((termitem) => {
            termitem.subjects = termitem.subjects.map((item) => item.subjectId)
          })
          this.modelObj = data
          this.dialog = true
        })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'courses',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
